.NotificationBannerBase {
    @mixin label;
    font-weight: 400;
    width: 100%;
    left: 0;
    right: 0;
    background-color: var(--blueDarken20LegacyColor);
    color: var(--whiteBaseColor);
    position: fixed;
    top: var(--headerMobileHeight);
    z-index: 4;
    transition:
        height 0.5s ease-out,
        top 0.4s ease-in-out;
    height: 0;

    @media (--tablet) {
        top: var(--headerHeight);
    }
}

:global(.InfoBanner--active) {
    & .NotificationBannerBase {
        top: calc(var(--headerMobileHeight) + var(--infoBannerMobileHeight));

        @media (--tablet) {
            top: calc(var(--headerHeight) + var(--infoBannerHeight));
        }
    }
}

:global($(hiddenHeaderClassSelector)) {
    & .NotificationBannerBase {
        top: 0;
    }
}

.Inner {
    @mixin centerElements;
    position: relative;
    width: 100%;
    text-align: left;
    padding: 14px 32px 13px 56px;

    @media (--tablet) {
        text-align: center;
        padding-left: 32px;
    }
}
