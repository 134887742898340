.ApplicationError {
    background-color: var(--whiteBaseColor);
    color: var(--errorLegacyColor);
    padding: 16px 16px 24px;
    margin: 0 0 32px;

    @media (--tablet) {
        padding-bottom: 32px;
    }
}

.Heading {
    @mixin p;
    margin-bottom: 8px;
    font-weight: 500;
}

.Body {
    @mixin label;
    display: inline;
    margin: 0;
    font-weight: 400;
}

.Variant--withoutHeading {
    padding: 16px;
}
