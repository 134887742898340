/* TODO / FIXME: temporary styling. "display" set in react component */

@keyframes ActivityIndicatorRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Inner {
    margin: 0 auto;
    border: 2px solid var(--redDarken20LegacyColor);
    border-top-color: transparent;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: ActivityIndicatorRotate 0.8s linear infinite;
    display: block;
}

.Inner--cover {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: 0;
    right: 0;
    bottom: 0;
}

.Outer {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    background-color: rgb(var(--whiteBaseRgb) / 50%);
    z-index: 2;
}

.Children {
    width: 100%;
    text-align: center;
    top: calc(50% + 1rem);
}
