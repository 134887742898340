@define-mixin myPageCalendarFontOverride {
    /* We cannot set inner elements for day names and
       week numbers, so to keep everything in line, we 
       have to have custom line-height
    */
    line-height: 32px;

    @media (--tablet) {
        line-height: 36px;
    }
}

@define-mixin myPageCalendarDay {
    width: 32px;
    height: 32px;

    @media (--tablet) {
        width: 36px;
        height: 36px;
    }
}
