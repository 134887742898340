.BookMeetingCalendarExpander {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
}

.Label {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
}

.Inner {
    position: relative;
    background: var(--whiteBaseColor);
}

.Input {
    @mixin resetButtonStyles;
    border: 1px solid var(--blackLighten50LegacyColor);
    background: transparent;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.Content {
    @mixin input 48px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.Icon {
    @mixin fixedRatioSvgIcon 24px;
}

.Error {
    @mixin fieldError;
    margin-top: 5px;
    color: var(--errorLegacyColor);
}

.Variant--rounded {
    & .Input,
    & .Inner,
    & .Content {
        @mixin themeRoundedCornersSmall;
    }
}

.Variant--error {
    & .Input {
        border-color: var(--errorLegacyColor);
    }
}
