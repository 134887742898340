@define-mixin datePickerOverrides {
    & > :global(.react-datepicker__month-container) {
        margin-bottom: 24px;

        & > :global(.react-datepicker__month) {
            margin: -8px 0;

            & :global(.react-datepicker__week) {
                margin: 8px -8px;
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 0;
                    transition: background-color 0.3s ease-out;
                    border-radius: 24px;
                }

                &:hover::after {
                    background: var(--blackLighten60Color);
                }
            }
        }

        & :global(.react-datepicker__day-names) {
            margin: 0 -8px;
            display: flex;
            justify-content: center;
        }
    }
}

.Dialog {
    padding-top: 8px;
    z-index: 10;
    min-width: 290px;
}

.Calendar {
    @mixin datePickerOverrides;

    & .WeekDay.WeekDay,
    & .Day.Day {
        text-align: center;

        @media (--mobileOnly) {
            width: 48px;
            height: 48px;
            line-height: 48px;
        }
    }
}
