.Icon {
    display: inline-block;
    position: relative;
    top: 2px;

    @media (--mobileOnly) {
        position: absolute;
        left: 32px;
        top: 15px;
    }

    & svg {
        width: 16px;
        height: 16px;
        display: block;
        fill: var(--whiteBaseColor);
        margin-right: 3px;
    }
}
