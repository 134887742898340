@define-mixin datePickerOverrides {
    & :global(.react-datepicker__header) {
        & :global(.react-datepicker__day-names) {
            display: flex;
            justify-content: center;
            margin: 0 8px;
        }
    }

    & :global(.react-datepicker__month) {
        margin: 0 0 24px;
    }

    & :global(.react-datepicker__week-number) {
        @mixin p50;
        @mixin myPageCalendarFontOverride;
        @mixin myPageCalendarDay;
        color: var(--blackLighten20Color);
        margin: 0;

        @media (--tablet) {
            &:not(:last-child) {
                margin-right: 4px;
            }
        }

        &:global(.react-datepicker__week-number--keyboard-selected) {
            background: var(--whiteBaseColor);
        }
    }

    & :global(.react-datepicker__children-container) {
        margin: 0;
        padding: 0;
        width: auto;
    }

    &:global(.react-datepicker) {
        display: flex;
        flex-direction: column;
        padding: 24px 12px;
        border: 0;
    }
}

.Calendar {
    @mixin datePickerOverrides;
}

.Variant--rounded {
    @mixin themeRoundedCornersSmall;
}
