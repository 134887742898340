.ImageBackgroundCallToAction {
    align-items: center;
    width: 100%;
    padding: 128px 0;

    @media (--tablet) {
        min-height: min(50vw, 680px);
    }
}

.Info {
    @mixin hyphenateWords manual;
    color: var(--whiteBaseColor);
}

.Heading {
    margin: 0;
    font-weight: 600;
}

.Variant--default {
    & .Info {
        padding-bottom: 24px;

        @media (--tablet) {
            lost-column: 6/12 0;
        }
    }

    & .Heading {
        @mixin h30;
        font-family: var(--serifFontFamily);
        margin-bottom: 16px;

        @media (--tablet) {
            @mixin h20;
        }
    }

    & .Description {
        @mixin p30;

        @media (--tablet) {
            @mixin p20;
        }
    }
}

.Variant--prominent {
    & .Content {
        @mixin centerElements;
        flex-direction: column;
    }

    & .Info {
        text-align: center;
        padding-bottom: 64px;

        @media (--tablet) {
            padding-bottom: 96px;
        }
    }

    & .Heading {
        @mixin p20;
        padding-bottom: 64px;

        @media (--tablet) {
            @mixin h40;
            padding-bottom: 96px;
        }
    }

    & .Description {
        @mixin h40;
        font-weight: 600;

        @media (--tablet) {
            @mixin h20;
        }
    }
}
