.TimeFrameSwitcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    & .Button {
        & .ButtonIcon {
            &:global(.SvgIcon):global(.SvgIcon):first-child {
                margin: 0;
            }
        }

        &.Button {
            @mixin onFocus {
                @mixin focusVisible -4px;
            }
        }
    }

    &.Variant--withWeekNumbers + :global(.react-datepicker__day-names) {
        &:global(.react-datepicker__day-names) {
            & :global(.react-datepicker__day-name):first-of-type {
                display: block;
            }
        }
    }
}

:global(.react-datepicker__header--custom):global(.react-datepicker__header--custom) {
    background: none;
    border: none;
    padding: 0;

    & :global(.react-datepicker__day-names) {
        margin-bottom: 8px;
    }

    & .DayName,
    & :global(.react-datepicker__day-name) {
        @mixin p50;
        @mixin myPageCalendarFontOverride;
        @mixin myPageCalendarDay;
        color: var(--blackLighten20Color);
        margin: 0 4px;

        @media (--tablet) {
            margin-left: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.CurrentDateLabel {
    @mixin p30;
    font-weight: 600;
}
