.Picture {
    & img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
    overflow: hidden;
}
