@define-mixin calendarButton {
    @mixin buttonTransitionBehavior single-arg(background-color, opacity);
    background: none;

    &:hover:not(.Variant--selected) {
        background-color: var(--blackLighten50Color);
    }

    &:active,
    &:active:hover {
        background-color: var(--blackLighten40Color);
    }
}

.CalendarDay.CalendarDay.CalendarDay {
    @mixin p30;
    @mixin calendarButton;
    @mixin myPageCalendarDay;
    @mixin myPageCalendarFontOverride;

    @mixin onFocus {
        @mixin focusVisible;
    }
    display: inline-block;
    position: relative;
    z-index: 1;
    color: var(--blackBaseColor);
    padding: 0;
    border-radius: 100%;
    font-family: var(--baseFontFamily);
    font-weight: normal;
    margin: 0 4px;

    &::after {
        display: block;
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 100%;
        left: 50%;
        bottom: 2px;
        transform: translateX(-50%);
        transition: background 300ms ease-in-out;
    }

    @media (--tablet) {
        margin-left: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &.Variant--today {
        color: var(--primaryHKBaseColor);
    }

    &.Variant--selected {
        color: var(--whiteBaseColor);
        background-color: var(--blackBaseColor);
    }

    &.Variant--highlighted {
        &::after {
            background: var(--support01HKDarken20Color);
        }
    }

    &.Variant--disabled {
        color: var(--blackLighten30Color);

        &:active,
        &:active:hover {
            background-color: none;
        }
    }

    &.Variant--hidden {
        display: none;
    }
}
