.FullName {
    @media (--tablet) {
        display: flex;
        gap: var(--gutterWidth);

        & > * {
            flex-basis: 50%;
        }
    }
}

.Heading {
    @mixin h30;
    font-weight: 600;
}

.PersonalDetailsSection {
    margin-bottom: 16px;
    border-bottom: 1px solid var(--blackLighten20Color);
}

.SubmitSection {
    margin-bottom: 16px;
}

.DateAndTime {
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;

        & > div {
            flex: 0.5;
        }
    }
}

.TermsCheckbox {
    @mixin p50;
    margin-bottom: 32px;
    color: var(--blackLighten20Color);
}

.SubmitButton {
    width: 100%;
}

.Optional {
    display: inline-block;
    font-weight: normal;
    text-transform: lowercase;
    margin-left: 4px;
}
