@define-mixin backgroundMediaSectionFullColorOverlay $backgroundColorRgb {
    background: rgb($backgroundColorRgb / 30%);

    @supports (background: -webkit-named-image(i)) {
        isolation: isolate;
    }
}

.BackgroundMediaSection {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
}

.Wrapper {
    @mixin wrapper;
    z-index: 1;

    &.Wrapper--grid {
        @media (--desktopHD) {
            @mixin grid;
            grid-template-columns: repeat(12, 1fr);
        }

        & .Content {
            @media (--desktopHD) {
                grid-column: 3 / span 8;
            }

            @media (--desktop2K) {
                grid-column: 4 / span 6;
            }
        }
    }
}

.Media {
    z-index: 0;
    mix-blend-mode: multiply;

    & img,
    & video,
    & .PlaceholderLogoWrapper {
        @mixin absoluteParentSize;
        object-fit: cover;

        /* For Safari only. More info here: https://www.bram.us/2021/06/23/css-at-supports-rules-to-target-only-firefox-safari-chromium/#update-2 */

        @supports (background: -webkit-named-image(i)) {
            mix-blend-mode: multiply;
        }
    }
}

.Variant--withCaption {
    display: grid;
    grid-template-rows: 100% 0;

    & .Media {
        @mixin absoluteParentSize;

        & img,
        & video,
        & .PlaceholderLogoWrapper {
            position: static;
        }
    }

    & .Figure {
        margin: 8px 0 0;

        & figcaption {
            @mixin wrapper;
            @mixin p50;
            margin-top: 0;
            margin-bottom: 0;
            color: var(--blackBaseColor);
        }
    }
}

.Variant--fullDark {
    @mixin backgroundMediaSectionFullColorOverlay var(--blackBaseRgb);
}

.Variant--fullLight {
    @mixin backgroundMediaSectionFullColorOverlay var(--whiteBaseRgb);

    & .Media {
        mix-blend-mode: lighten;

        @supports (background: -webkit-named-image(i)) {
            & img,
            & video,
            & .PlaceholderLogoWrapper {
                mix-blend-mode: lighten;
            }
        }
    }
}

.Variant--topToBottomGreyGradient {
    background: linear-gradient(180deg, rgb(var(--blackBaseRgb) / 65%) 0%, rgb(var(--blackLighten20Rgb) / 65%) 100%);
}

.Variant--leftToRightDarkGreyGradient {
    background: linear-gradient(90deg, rgb(var(--blackBaseRgb) / 80%) 0%, transparent 100%);
}

.Variant--upperTransparentLineGradient {
    background: linear-gradient(
        180deg,
        rgb(var(--blackBaseRgb) / 80%) 0%,
        transparent 25%,
        rgb(var(--blackBaseRgb) / 80%) 100%
    );
}
