.Controls {
    display: flex;
    gap: 8px;
}

.ButtonContainer {
    flex: 1;
}

.Button {
    width: 100%;
    height: 100%;
}
