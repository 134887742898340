@define-mixin placeholderVariant $backgroundColor, $logoColor {
    & .LogoWrapper {
        background-color: $backgroundColor;
    }

    & .Logo {
        background-color: $logoColor;
    }
}

.LogoWrapper {
    @mixin centerElements;
    width: 100%;
    height: 100%;
}

.Logo {
    position: relative;
    width: 50%;
    height: 50%;
    margin: 16px;
    mask: svg-load('placeholder.svg') no-repeat center;
    mask-size: contain;
}

.Variant--default {
    @mixin placeholderVariant var(--blackLighten20Color), var(--blackLighten30Color);
}

.Variant--light {
    @mixin placeholderVariant var(--blackLighten50Color), var(--blackLighten40Color);
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
    overflow: hidden;
}
