.Content {
    @mixin centerElements;
    flex-direction: column;
}

.Heading {
    @mixin h30;
    font-weight: 600;
    margin-bottom: 64px;
}

.SvgIcon {
    @mixin fixedRatioSvgIcon 80px, var(--notificationSuccessBaseColor);
    margin-bottom: 64px;
}

.ConfirmationMessage {
    @mixin p10;
    margin-bottom: 32px;

    & p:last-child {
        margin-bottom: 0;
    }
}

.Button {
    width: 100%;
}
