.Overlay {
    $topBannerHeight: 80px;

    @mixin absoluteParentSize;
    position: fixed;
    z-index: 1100;
}

.Background {
    @mixin absoluteParentSize;
    @mixin animatePropertySlower background;

    &:global(.appear-active),
    &:global(.enter-active),
    &:global(.enter-done) {
        background: rgb(var(--blackBaseRgb) / 70%);
    }
}

.Inner {
    @mixin verticalSlideAnimation;
    position: absolute;
    background: var(--blackLighten60Color);
    color: var(--blackBaseColor);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    @media (--tablet) {
        @mixin horizontalSlideAnimation;
        border-top-right-radius: 0;
    }

    &.Inner {
        width: 100%;
        height: 95%;
        top: auto;
        bottom: 0;

        @media (--tablet) {
            width: calc(7 / 12 * 100%);
            height: 85%;
            right: 0;
            left: auto;
        }

        @media (--desktopHD) {
            width: calc(5 / 12 * 100%);
        }

        @media (--desktop2K) {
            width: calc(5 / 12 * var(--wrapperWidth));
        }
    }
}

.TopBanner {
    height: $topBannerHeight;
}

.Content {
    @mixin p10;
    height: calc(100% - $topBannerHeight);
    overflow: hidden auto;
    padding: 0 var(--wrapperHorizontalPadding) 32px 32px;

    @media (--tablet) {
        padding-bottom: 64px;
    }
}

.CloseButton {
    @mixin leftCloseButton 24px, 24px, 32px;
}
